import { CardMedia, CardMediaProps, Chip, Stack } from "@mui/material";
import { PropsWithChildren, ReactNode } from "react";
import { FaroButton, FaroButtonProps } from "../button/faro-button";
import { neutral, orange } from "../colors";
import { FaroText } from "../text/faro-text/faro-text";
import { BasePopover, BasePopoverProps } from "./base-popover";

export type PopoverPage = {
  /** The current page being displayed. */
  current: number;

  /** The total number of pages available. */
  total: number;
};

export type FaroPopoverProps = Pick<
  BasePopoverProps,
  | "dark"
  | "open"
  | "closeOnClickOutside"
  | "onClose"
  | "placement"
  | "anchorEl"
  | "showCloseButton"
  | "disablePortal"
  | "modifiers"
  | "sx"
> & {
  /** The title text to display at the top of the popover. */
  title?: ReactNode;

  /** The props to create an image card in the popover. */
  media?: CardMediaProps<"img">;

  /** A description text to display in the popover. */
  description?: ReactNode;

  /** If provided, display a chip in the popover with the given label. */
  chipLabel?: ReactNode;

  /** If provided, add a pagination text to the popover (e.g. "1 of 3") */
  page?: PopoverPage;

  /**
   * Actions that the user can execute in the popover.
   *
   * Uses the `FaroButton` component internally and provides the same configuration options.
   */
  actions?: FaroButtonProps[];
};

/**
 * @returns  A popover element styled according to the FARO design system.
 */
export function FaroPopover({
  title,
  media,
  description,
  chipLabel,
  page,
  actions,
  dark,
  sx,
  children,
  ...rest
}: PropsWithChildren<FaroPopoverProps>): JSX.Element {
  return (
    <BasePopover
      titleBar={
        <FaroText
          variant="heading12"
          color="inherit"
          sx={{ textTransform: "uppercase" }}
        >
          {title}
        </FaroText>
      }
      dark={dark}
      sx={{
        // Make sure the popover is treated as a tooltip (i.e., covering other content)
        zIndex: ({ zIndex }) => zIndex.tooltip,

        ...sx,
      }}
      {...rest}
    >
      {media && <CardMedia component="img" width="100%" {...media} />}
      {chipLabel && <PopoverChip chipLabel={chipLabel} />}
      {description && (
        <FaroText variant="bodyS" color="inherit">
          {description}
        </FaroText>
      )}
      {children}
      {(!!page || !!actions) && (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          gap={1}
        >
          {page ? (
            <FaroText variant="bodyS" color="inherit">
              {page.current} of {page.total}
            </FaroText>
          ) : (
            <div />
          )}
          {actions && (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              gap={1}
            >
              {actions.map((actionProps, index) => (
                <FaroButton key={index} dark={dark} {...actionProps} />
              ))}
            </Stack>
          )}
        </Stack>
      )}
    </BasePopover>
  );
}

/** @returns Custom chip to use in the popover, until we have a complete design for a reusable component. */
export function PopoverChip({
  chipLabel,
}: Pick<FaroPopoverProps, "chipLabel">): JSX.Element {
  return (
    <Chip
      label={
        <FaroText
          variant="labelS"
          sx={{
            textTransform: "uppercase",
            color: neutral[950],
          }}
        >
          {chipLabel}
        </FaroText>
      }
      sx={{
        py: 0.125,
        px: 0.5,
        height: "unset",
        backgroundColor: orange[500],
        width: "fit-content",

        "& .MuiChip-label": {
          p: 0,
        },
      }}
    />
  );
}

import {
  Identify,
  Types,
  identify,
  init,
  setOptOut,
  setUserId,
  track,
} from "@amplitude/analytics-browser";
import { EventProps, IInitParams, UserPropertyValue } from "../analytics-types";

interface IUserProperty {
  /** Name of the user property */
  propertyName: string;

  /** Value of the user property */
  propertyValue: UserPropertyValue;
}
/**
 * The client to handle the amplitude events and report them
 */
export class AmplitudeClient {
  /**
   * Initialize Amplitude analytics with the given project API key
   *
   * @param apiKey API Key of the amplitude analytics project
   * @param props Initial props to set the configurations of the amplitude
   * @returns A promise from the init function of Amplitude
   * @see https://developers.amplitude.com/docs/typescript-browser#initializing-sdk
   */
  init(apiKey: string, props?: IInitParams): Types.AmplitudeReturn<void> {
    if (props) {
      return init(apiKey, props.userId, {
        ...props.initConfigs,
      });
    }
    return init(apiKey);
  }

  /**
   * Sets the user ID in the amplitude analytics
   *
   * @param userId The ID of the user
   */
  set userId(userId: string) {
    setUserId(userId);
  }

  /**
   * Sets the properties related to the current user
   */
  set userProperties(userProps: EventProps) {
    const identifyObj = new Identify();

    for (const key in userProps) {
      identifyObj.set(key, userProps[key]);
    }

    identify(identifyObj);
  }

  /**
   * sets the property related to the current user
   *
   * @deprecated @see userProperties instead
   */
  set userProperty({ propertyName, propertyValue }: IUserProperty) {
    const identifyObj = new Identify();
    identifyObj.set(propertyName, propertyValue);

    identify(identifyObj);
  }

  /**
   * Tracks an event of a certain Type with an optional additional details
   *
   * @param type Type of the event to be recorded
   * @param properties Additional details related to the event
   * @returns A promise with the result of the tracking event
   * @see https://developers.amplitude.com/docs/typescript-browser#tracking-an-event
   */
  trackEvent(
    type: string,
    properties?: Record<string, unknown>,
  ): Types.AmplitudeReturn<Types.Result> {
    // In case one wants to check the status of event logging, the track function does return a promise
    return track(type, properties);
  }

  /**
   * Enable/disable the analytics tracking of the user
   *
   * @param shouldOptOut True if the user wants to opt out of analytical tracking
   * @see https://developers.amplitude.com/docs/typescript-browser#tracking-switch
   */
  set optOutOfAnalytics(shouldOptOut: boolean) {
    setOptOut(shouldOptOut);
  }
}

import { Optional } from "@faro-lotv/foundation";
import { GUID } from "@faro-lotv/ielement-types";
import {
  SendTypedAuthenticatedJsonRequestParams,
  TokenProvider,
  sendAuthenticatedJsonRequest,
} from "../authentication";
import {
  CancelRegistrationParams,
  RegistrationTaskType,
  StartCaptureTreeRegistrationParams,
  StartPointCloudMergeParams,
  StartRegistrationParams,
} from "./registration-api-parameters";
import {
  CancelRegistrationResponse,
  RegistrationStartedJobResponse,
  isCancelRegistrationResponse,
  isRegistrationStartedJobResponse,
} from "./registration-api-responses";

/** The path to the cloud to cloud registration trigger function. */
const C2C_HTTP_TRIGGER_PATH = "/c2c/api/http-trigger";

/** The path to the multi cloud and capture tree registration trigger function. */
const MC_HTTP_TRIGGER_PATH = "/mc/api/http-trigger";

type RegistrationApiClientParams = {
  /** Id of the current project. */
  projectId: GUID;
  /** The unique ID of the user to make the request for. */
  userId: GUID;
  /** To get authentication tokens for this project. */
  tokenProvider: TokenProvider;
  /** The base url of the registration API. */
  baseUrl: string;
  /** The ID of the client making the request. */
  clientId?: string;
};

/** The client for the Registration API. */
export class RegistrationApiClient {
  /** Id of the current project. */
  private projectId: GUID;
  /** The unique ID of the user to make the request for. */
  private userId: GUID;
  /** To get authentication tokens for this project. */
  private tokenProvider: TokenProvider;
  /** The base url of the registration API. */
  private baseUrl: string;
  /** The ID of the client making the request. */
  private clientId?: string;

  /** Create a new API client to make requests to the registration backend. */
  constructor({
    projectId,
    userId,
    tokenProvider,
    baseUrl,
    clientId,
  }: RegistrationApiClientParams) {
    this.projectId = projectId;
    this.userId = userId;
    this.tokenProvider = tokenProvider;
    this.baseUrl = baseUrl;
    this.clientId = clientId;
  }

  /**
   * Start the automatic registration for the given point clouds.
   *
   * @returns The response of the backend.
   */
  startRegistration({
    referenceCloudIds,
    modelCloudIds,
    algorithmSettings,
    modelToRefWorldTransform,
  }: StartRegistrationParams): Promise<RegistrationStartedJobResponse> {
    return this.makeAuthorizedRequest({
      httpMethod: "POST",
      path: C2C_HTTP_TRIGGER_PATH,
      // TODO: Keep these for now as fallback. Once we migrated all params to the body on the worker side, this can be removed.
      // Current behavior in the worker overwrites these with the ones sent in the request body
      queryParams: {
        taskType: RegistrationTaskType.c2cRegistration,
        userId: this.userId,
        projectId: this.projectId,
        pointCloudId1: referenceCloudIds[0],
        pointCloudId2: modelCloudIds[0],
        registrationAlgorithm: algorithmSettings.algorithm,
      },
      requestBody: {
        userId: this.userId,
        projectId: this.projectId,
        referencePointclouds: referenceCloudIds,
        dataPointclouds: modelCloudIds,
        registrationAlgorithm: algorithmSettings.algorithm,
        parameters: algorithmSettings.parameters,
        initialTransformation: modelToRefWorldTransform,
      },
      typeGuard: isRegistrationStartedJobResponse,
    });
  }

  /**
   * Terminate an ongoing registration job.
   *
   * @returns The response of the backend.
   */
  cancelRegistration({
    jobId,
    modelCloudIds,
    referenceCloudIds,
  }: CancelRegistrationParams): Promise<CancelRegistrationResponse> {
    return this.makeAuthorizedRequest({
      httpMethod: "DELETE",
      path: `${C2C_HTTP_TRIGGER_PATH}/${jobId}`,
      requestBody: {
        userId: this.userId,
        projectId: this.projectId,
        referencePointclouds: referenceCloudIds,
        dataPointclouds: modelCloudIds,
      },
      typeGuard: isCancelRegistrationResponse,
    });
  }

  /**
   * Starts the automatic registration of _all_ point clouds that are found in the capture tree
   * of the given project.
   *
   * @returns The response of the backend.
   */
  async startCaptureTreeRegistration({
    revisionId,
    ignoreLocalEdges,
    registrationAlgorithm,
    autoPublish,
    parameters,
    preset,
  }: StartCaptureTreeRegistrationParams): Promise<RegistrationStartedJobResponse> {
    return await this.makeAuthorizedRequest({
      httpMethod: "POST",
      path: MC_HTTP_TRIGGER_PATH,
      requestBody: {
        userId: this.userId,
        projectId: this.projectId,
        captureTree: true,
        revisionId,
        ignoreLocalEdges,
        registrationAlgorithm,
        autoPublish,
        parameters,
        preset,
      },
      typeGuard: isRegistrationStartedJobResponse,
    });
  }

  /**
   * Merges the given point clouds into a single point cloud and uploads it as a new dataset.
   *
   * @returns The ID of the job started in the backend.
   */
  async startPointCloudMerge({
    outputTimeSeriesId,
    outputDate,
    outputSectionName,
    pointCloudIds,
  }: StartPointCloudMergeParams): Promise<RegistrationStartedJobResponse> {
    return await this.makeAuthorizedRequest({
      httpMethod: "POST",
      path: C2C_HTTP_TRIGGER_PATH,
      requestBody: {
        taskType: RegistrationTaskType.mergePointClouds,
        userId: this.userId,
        projectId: this.projectId,
        outputDate,
        outputTimeSeriesId,
        outputSectionName,
        pointclouds: pointCloudIds,
      },
      typeGuard: isRegistrationStartedJobResponse,
    });
  }

  /**
   * Make an authorized request to the registration API.
   *
   * @param params The parameters to send with the request.
   * Mostly the same as for `sendAuthenticatedJsonRequest`.
   * The base URL, token provider and client ID are set automatically.
   * @returns the parsed json response
   */
  private makeAuthorizedRequest<T>(
    params: Optional<
      SendTypedAuthenticatedJsonRequestParams<T>,
      "tokenProvider" | "clientId"
    >,
  ): Promise<T> {
    return sendAuthenticatedJsonRequest({
      ...params,
      baseUrl: this.baseUrl,
      tokenProvider: this.tokenProvider,
      clientId: this.clientId,
    });
  }
}

import { GUID } from "@faro-lotv/foundation";
import { useEffect } from "react";
import { Analytics } from "./analytics-service";

/**
 * Initialize the analytics client for the application unless the user is from either HoloBuilder or Faro
 *
 * @param key amplitude key
 * @param currentUserEmail current user email if an user is logged in
 * @param companyId Optional ID of the company to attach to every event
 */
export function useAnalyticsInitialization(
  key?: string,
  currentUserEmail?: string,
  companyId?: GUID,
): void {
  useEffect(() => {
    // Exclude @faro.com and @holobuilder.com and anonymous users from the analytics
    // we can't track anonymous users without a consent dialog that we're not implementing
    if (!key || isInternalUserEmail(currentUserEmail)) {
      return;
    }
    Analytics.init(key);

    const props = companyId ? { userProps: { companyId } } : undefined;

    if (props !== undefined) {
      Analytics.props = { ...props };
    }
  }, [currentUserEmail, key, companyId]);
}

/**
 * check if user email is from @faro.com or @holobuilder.com or anonymous
 *
 * @param userEmail the given user email
 * @returns true if the given usr email is from @faro.com or @holobuilder.com or anonymous, false otherwise
 */
export function isInternalUserEmail(userEmail?: string): boolean {
  return (
    !userEmail ||
    userEmail.includes("@faro.com") ||
    userEmail.includes("@holobuilder.com")
  );
}
